.AnchorButton {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.table-invoice th.sticky-1, .table-invoice td.sticky-1
{
  position:sticky;
  left:0px;
  background-color:#e1e1e1;
  z-index: 9999;
}

.table-invoice th.sticky-2, .table-invoice td.sticky-2
{
  position:sticky;
  left:12%;
  background-color:#e1e1e1;
  z-index: 9999;
}